<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseCard :title="$t('title')" class="search-by-year">
		<BRow no-gutters>
			<BCol cols="auto" class="subtitle-col">
				<p class="h5">
					{{ $t('subtitle') }}
				</p>
			</BCol>
			<BCol cols="auto">
				<InfoModal
					ref="NoUpdateInfo"
					:button-icon="['fal', 'info-circle']"
					:modal-title="$t('modal.premiumHeader')"
					centered
					:is-static="true"
					:automation-id="getAutomationId('searchByYear')"
				>
					<p class="modal-font-weight">{{ $t('modal.premiumParagraphOne') }}</p>
					<p class="modal-font-weight">{{ $t('modal.premiumParagraphTwo') }}</p>
				</InfoModal>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p>
					{{ $t('info') }}
					<TextModal
						:id="getUniqueId('text-modal')"
						:text="$t('coPays')"
						:modal-title="$t('modal.header')"
						:automation-id="getAutomationId('textModal')"
					>
						<div>
							<p class="co-pay-">{{ info }}</p>
						</div>
					</TextModal>
				</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseSelect
					:value="year"
					class="yearSelect"
					:is-plain="true"
					:options="getStatementYears()"
					:validation-rules="{ required: required }"
					:validation-messages="{ required: $t('error.yearRequired') }"
					:automation-id="getAutomationId('year')"
					@change="onChange"
				></BaseSelect>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					class="m-0 search-button"
					variant="primary"
					:label="$t('button.download')"
					pill
					is-right
					:icon="['fal', 'print']"
					icon-position="left"
					:automation-id="getAutomationId('searchByYearCard')"
					:data-tracking-id="getAutomationId('searchByYearCard')"
					@click="onClick"
				></BaseButton>
			</BCol>
		</BRow>
	</BaseCard>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
// This component renders Main Member card component with the members details on the card.
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BaseSelect from '@/components/common/base/BaseSelect';
import BaseButton from '@/components/common/base/BaseButton';
import TextModal from '@/components/common/TextModal.vue';
import InfoModal from '@/components/common/InfoModal';
import BaseCard from '@/components/common/card/BaseCard';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'PremiumPaymentsSearchByDateCard',
	components: {
		BRow,
		BCol,
		BaseSelect,
		BaseButton,
		TextModal,
		InfoModal,
		BaseCard
	},
	mixins: [IdMixin],
	props: {
		required: {
			type: Boolean,
			default: false
		}
	}
})
export default class PremiumPaymentsSearchByYearCard extends Vue {
	year = new Date().getFullYear().toString();
	image = "'@/assets/sort-down-solid.svg'";
	securityPolicy = null;
	defaultBrand = 'MBC';

	toggleModalShow = false;

	get modalShow() {
		return this.toggleModalShow;
	}

	get branding() {
		const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		return brand ? brand : this.defaultBrand;
	}

	get info() {
		if (this.branding === 'MBC') {
			return this.$t('modal.info');
		}
		return this.$t('modal.genInfo');
	}

	open() {
		this.toggleModalShow = true;
	}

	close() {
		this.toggleModalShow = false;
	}

	/**
	 * Returns list of provinces
	 * @returns {array}
	 */
	getStatementYears() {
		const currentYear = new Date().getFullYear();
		return [
			{ value: currentYear.toString(), text: currentYear },
			{ value: (currentYear - 1).toString(), text: currentYear - 1 },
			{ value: (currentYear - 2).toString(), text: currentYear - 2 },
			{ value: (currentYear - 3).toString(), text: currentYear - 3 }
		];
	}

	/**
	 * emit click event
	 */
	onClick() {
		this.$emit('click', this.year);
	}

	/**
	 * Handle onchange event
	 * @param {string} value The value.
	 */
	onChange(year) {
		this.year = year;
	}
}
</script>
<style lang="scss" scoped>
.modal-font-weight {
	font-weight: 300;
}

.yearSelect {
	width: 5.5em;
}

.yearSelect option {
	width: 1em;
}

.yearSelect ::v-deep option:disabled {
	display: none;
}
.yearSelect ::v-deep .form-control {
	font-size: $body-small-font-size;
	font-weight: $font-regular;
	color: $black-text;
	padding-left: 0.5em;
	height: 2.7em;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, $label-grey 50%),
		linear-gradient(135deg, $label-grey 50%, transparent 50%),
		linear-gradient(to right, $white, $white);
	background-position: calc(100% - 14px) calc(1em + 1px), calc(100% - 8px) calc(1em + 1px),
		calc(100% - 1.5em) 0.5em;
	background-size: 6px 6px, 6px 6px, 1px 1.5em;
	background-repeat: no-repeat;
}

.search-button {
	min-width: 120px;
	border: 1px solid $blue;
	background-color: $blue;
	border-radius: 30px;
	font-family: $josefin-font-family;
	font-size: $body-regular-button-font-size;
	font-weight: normal;
	margin-left: 1em;
}
.row-height {
	height: 4.5em;
}
::v-deep .btn .svg-left {
	margin-right: 0.7em;
}
.subtitle-col {
	@media (max-width: 320px) {
		width: 80%;
	}
}
</style>

<i18n>
{
  "en": {
    "title": "Annual Premium Statement",
    "subtitle": "Download your annual statements",
    "info": "Friendly reminder, this statement only reflects the premiums you have paid and does not include other costs, like ",
    "coPays": "co-pays.",
    "modal": {
      "header": "What's a co-payment?",
			"info": "Once Medavie Blue Cross has paid for your claim, your co-payment is the amount left that you pay for out-of-pocket. For example: if we pay 80% of your claim, your co-payment will be 20%.",
			"genInfo": "Once Blue Cross has paid for your claim, your co-payment is the amount left that you pay for out-of-pocket. For example: if we pay 80% of your claim, your co-payment will be 20%.",
      "premiumHeader": "What’s an annual premium statement?",
			"premiumParagraphOne": "Your annual premium statement shows all the premiums you’ve paid throughout your policy year.",
      "premiumParagraphTwo": "Your policy year might not be the same as a calendar year. To confirm what your policy year is, check your benefits booklet."
    },
    "button": {
      "download": "Download"
    },
    "error": {
      "yearRequired": "Please enter a valid date."
    }
  },
  "fr": {
    "title": "Relevé de primes annuel",
    "subtitle": "Téléchargez votre relevé annuel",
    "info": "Nous vous rappelons que ce relevé ne tient compte que des primes que vous avez payées, et non des autres coûts, comme les ",
    "coPays": "quotes-parts.",
    "modal": {
      "header": "Qu’est-ce qu’une quote-part?",
			"info": "La quote-part est le montant qu’il vous reste à débourser une fois que Croix Bleue Medavie a payé pour votre demande de règlement. Par exemple, si nous remboursons 80 % du montant de votre demande de règlement, votre quote-part correspondra au 20 % restant.",
			"genInfo": "La quote-part est le montant qu’il vous reste à débourser une fois que Croix Bleue a payé pour votre demande de règlement. Par exemple, si nous remboursons 80 % du montant de votre demande de règlement, votre quote-part correspondra au 20 % restant.",
      "premiumHeader": "Qu'est-ce qu'un relevé de primes annuel?",
			"premiumParagraphOne": "Votre relevé de primes annuel fait état de toutes les primes que vous avez payées durant l’année de la police.",
      "premiumParagraphTwo": "Votre année de police ne correspond pas nécessairement à l’année civile. Consultez votre brochure de garanties pour connaître votre année de police."
    },
    "button": {
      "download": "Télécharger"
    },
    "error": {
      "yearRequired": "Veuillez entrer une date valide."
    }
  }
}
</i18n>
