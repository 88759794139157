<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseCard :title="$t('title')" title-class="mb-0">
		<label class="subtitle mr-2">{{ dateRangeString }}</label>
		<BaseButton
			:label="$t('subtitle.modifyDateRange')"
			:pill="false"
			variant="link"
			class="p-0"
			:automation-id="'premiumStatementsModifyDateRange'"
			@click="hideSearchPage"
		/>
		<div class="my-3 d-block d-md-none">
			{{ $t('label.total') }} <b>{{ premiumPaymentHistory.totalAmount }}</b>
		</div>
		<BaseTable
			:date-range="dateRange"
			:items="premiumPaymentHistory.premiums"
			stacked="md"
			:fields="columns"
			:current-page="currentPage"
			:per-page="perPage"
			small
			:class="['base-table-styles', !isEnglish ? 'base-table-styles-fr' : '', 'mt-2']"
		>
			<template #custom-foot>
				<BTr scope="row" class="footer d-none d-md-table-row">
					<BTh class="footer-total-label" role="cell">{{ $t('label.total') }}</BTh>
					<BTh role="cell" class="footer-spacer-cell"> </BTh>
					<BTh class="footer-total-amount" role="cell">{{
						`${premiumPaymentHistory.totalAmount}`
					}}</BTh>
				</BTr>
			</template>
			<template #table-caption></template>
		</BaseTable>
		<BasePagination
			:current-page="currentPage"
			:total-rows="totalRows"
			:per-page="perPage"
			@input="pageChange"
		/>
	</BaseCard>
</template>
<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import PremiumStatementsModel from '@/models/PremiumStatements.js';
import { BTr, BTh } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseTable from '@/components/common/base/BaseTable';
import BasePagination from '@/components/common/base/BasePagination';
import { formatDateForUi } from '@/utils/date';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'PremiumPaymentSearchResultsCard',
	components: {
		BaseCard,
		BaseButton,
		BaseTable,
		BasePagination,
		BTr,
		BTh
	},
	mixins: [IdMixin],
	props: {
		dateRange: {
			type: Object,
			default: null
		},
		paymentHistory: {
			type: Object,
			default: null
		},
		columns: {
			type: Array,
			default: null
		},
		perPage: {
			type: Number,
			default: 50
		}
	},
	watch: {
		locale() {
			this.getPremiumPaymentsHistory();
		}
	}
})
export default class PremiumPaymentSearchResultsCard extends Vue {
	currentPage = 1;
	isModalVisible = false;
	//Set it as local value so I won't have to change the prop value if a member changes their locale.
	premiumPaymentHistory = this.paymentHistory;
	totalRows = this.premiumPaymentHistory?.premiums ? this.premiumPaymentHistory.premiums.length : 0;

	mounted() {
		this.currentPage = 1;
	}

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	get dateRangeString() {
		const options = { year: 'numeric', month: 'long', day: '2-digit' };
		const fromDate = new Date(
			this.dateRange.fromDate.split('-')[0],
			this.dateRange.fromDate.split('-')[1] - 1,
			this.dateRange.fromDate.split('-')[2]
		).toLocaleDateString(this.locale, options);
		const toDate = new Date(
			this.dateRange.toDate.split('-')[0],
			this.dateRange.toDate.split('-')[1] - 1,
			this.dateRange.toDate.split('-')[2]
		).toLocaleDateString(this.locale, options);
		return `${fromDate} ${this.$t('subtitle.to')} ${toDate}`;
	}

	pageChange(page) {
		this.currentPage = page;
	}
	/**
	 * Emits event that returns the user to select new dates
	 */
	hideSearchPage() {
		this.$emit('hidePage');
	}

	// This method will only be called if the member updates the locale.
	async getPremiumPaymentsHistory() {
		this.$store.dispatch('updateLoading', true);
		const locale = this.$store.state.i18n.locale;
		let response = await PremiumStatementsModel.getPremiumsByDateRange(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			locale,
			this.dateRange.fromDate.split('-').join(''),
			this.dateRange.toDate.split('-').join('')
		);
		if (response && response.status <= 204) {
			if (response.data.premiums) {
				const formatDate = (paymentDate) => {
					return formatDateForUi(paymentDate, this.$store.state.i18n.locale);
				};

				let premiums = response.data.premiums?.map((premium) => ({
					...premium,
					transactionDate: formatDate(premium.transactionDate),
					amount:
						locale === 'fr'
							? premium.amount
									.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })
									.replace('CA', '')
							: premium.amount.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })
				}));
				this.premiumPaymentHistory = {
					totalAmount:
						locale === 'fr'
							? response.data.totalAmount
									.toLocaleString('fr-CA', {
										style: 'currency',
										currency: 'CAD'
									})
									.replace('CA', '')
							: response.data.totalAmount.toLocaleString('en-CA', {
									style: 'currency',
									currency: 'CAD'
							  }),
					premiums: premiums
				};
			} else {
				this.isModalVisible = true;
				this.$store.dispatch('error', {
					name: 'premium-statement-error',
					title: null,
					err: this.$t('error.premiumPaymentsNotAvailable')
				});
			}
		} else {
			this.isModalVisible = true;
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.wentWrongHeader'),
				err: this.$t('error.wentWrong')
			});
		}
		this.$store.dispatch('updateLoading', false);
	}
}
</script>
<style lang="scss" scoped>
.footer {
	background: $light-grey;
	font-weight: $font-regular;
	font-size: $body-regular-button-font-size;
	font-family: $josefin-font-family;
}
.navigationIcon {
	width: 0.6em;
}
.navigationIcon ::v-deep svg {
	color: $blue;
}
::v-deep .card-title {
	margin-bottom: 10px;
}

.navigationIcon:disabled {
	width: 0.8em;
	color: $grey;
}

.download-button {
	min-width: 120px;
	background-color: $blue;
	border-radius: 30px;
	font-family: $josefin-font-family;
	font-size: $body-small-font-size;
	font-weight: $font-regular;
	margin-left: 1em;
}

.base-table-styles ::v-deep tfoot {
	@media (max-width: 767px) {
		display: initial !important;
	}
}

.base-table-styles ::v-deep tr:nth-child(even) {
	@include media-breakpoint-down(sm) {
		background-color: $gray-very-light;
	}
}

.footer-total-label {
	text-align: left;
	vertical-align: middle;
	@media (max-width: 767px) {
		display: none;
	}
}

.footer-total-amount {
	text-align: right;
	vertical-align: middle;

	@media (max-width: 767px) {
		width: 25%;
		text-align: center;
	}
}

// If you need to use translations for the content. You can set specific css based on html:lang().
// Couldn't figure out how to use i18n in there.
//html:lang(fr) .footer-total-amount::before {
.footer-total-amount::before {
	@media (max-width: 767px) {
		content: 'Total';
		padding-right: 1em;
		padding-left: 1em;
	}
}

.footer-spacer-cell {
	@media (max-width: 767px) {
		display: none;
	}
}

// CSS specific to french screens
.base-table-styles-fr ::v-deep .th-amount-class {
	text-align: center;
}

.base-table-styles ::v-deep th {
	font-size: 16px;
	font-family: $font-family-headline;
	font-weight: 300;
}

.base-table-styles ::v-deep tbody tr [data-label]::before {
	font-size: 16px;
	font-family: $font-family-headline;
	font-weight: 300;
}

// Amount column
.base-table-styles ::v-deep .th-amount-class {
	text-align: right;
}
.base-table-styles ::v-deep .td-amount-class {
	text-align: right;
	@media (max-width: 767px) {
		text-align: left;
	}
}

// Type Column
.base-table-styles ::v-deep .th-type-class {
	text-align: center;
}

.base-table-styles ::v-deep .td-type-class {
	text-align: center;
	@media (max-width: 767px) {
		text-align: left;
	}
}
</style>

<i18n>
{
"en": {

	"title": "Payment Statement Details",
		"subtitle": {
			"modifyDateRange":"Modify date range",
			"to": "to"
        },
		"label": {
            "total":"Total:"
		},
		"error": {
			"premiumPaymentsNotAvailable": "We can't find any results related to your search. Please try a new search.",
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
    },
"fr": {
		"title": "Cherchez vos relevés",
		"subtitle": {
			"modifyDateRange":"Modifier l'intervalle de dates",
			"to": "au"
        },
		"label": {
            "total":"Total :"
		},
		"error": {
			"premiumPaymentsNotAvailable": "Nous n’avons trouvé aucun résultat pour votre recherche. Veuillez essayer une nouvelle recherche.",
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}
    }
}
</i18n>
