import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Premium payment statements
 */
export default class PremiumStatements extends Model {
	/**
	 * This method will call the api to return all the premium paymnts made between the dates.
	 * @param {String} username
	 * @param {String} locale
	 * @param {String} fromDate
	 * @param {String} toDate
	 * @returns
	 */
	static async getPremiumsByDateRange(username, token, locale, fromDate, toDate) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/premiums?fromDate=${fromDate}&toDate=${toDate}`;
			let response = await axios
				.get(url, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});

			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: 'premiumStatements',
				err
			});
		}
	}

	/**
	 * This method will call the api to return a pdf of the member premium payments made in a year.
	 * @param {String} username
	 * @param {String} locale
	 * @param {String} year
	 * @returns
	 */
	static async getPremiumsPDFByDateRange(username, token, locale, fromDate, toDate) {
		try {
			const premiumsPDFByDateRange = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});

			const response = await premiumsPDFByDateRange.get(
				`/${username}/premiums/statement?fromDate=${fromDate}&toDate=${toDate}`
			);
			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: 'premiumStatements',
				err
			});
		}
	}

	/**
	 * This method will call the api to return a pdf of the member premium payments made in a year.
	 * @param {String} username
	 * @param {String} locale
	 * @param {String} year
	 * @returns
	 */
	static async getPremiumsPDFByYear(username, token, locale, year) {
		try {
			const premiumsPDF = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});

			const response = await premiumsPDF.get(`/${username}/premiums/statement?year=${year}`);
			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: 'premiumStatements',
				err
			});
		}
	}
}
