<template>
	<manage-your-account-template
		:page-title="$t('title')"
		:full-width="true"
		:class="[$store.state.loading ? 'd-none' : '']"
	>
		<h2 class="moneris-subtitle mt-n3">{{ $t('subTitle') }}</h2>

		<div class="d-flex flex-wrap align-items-end">
			<CreditCardImages class="flex-grow-1 credit-card-images mb-3" />
			<img :src="logo()" :alt="$t('imageAlt')" class="moneris-logo mb-3" />
		</div>
		<div v-if="monerisHtmlText">
			<iframe
				id="moneris"
				ref="moneris"
				scrolling="no"
				class="iframe"
				:srcdoc="monerisHtmlText"
				@load="iframeLoadComplete"
			>
			</iframe>
		</div>
		<br />
	</manage-your-account-template>
</template>

<script>
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import CreditCardImages from '@/components/common/CreditCardImages';
import * as MonerisHtml from '@/constants/MonerisHtml.js';
import * as Routes from '@/constants/Routes.js';
import isMobile from '@/mixins/mobile';
import CreditCard from '@/models/CreditCard';

export default {
	name: 'MonerisPage',
	components: {
		ManageYourAccountTemplate,
		CreditCardImages
	},
	mixins: [isMobile],
	data() {
		return {
			monerisHtmlText: null,
			ticket: null
		};
	},
	computed: {
		cardClass() {
			return this.isMobile() ? 'moneris-mobile-card' : 'moneris-card';
		},
		/**
		 * Obtain user brand if none is return default to MBC
		 */
		brand() {
			let securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
			return securityPolicy && securityPolicy.brand ? securityPolicy.brand : 'MBC';
		},
		isMedavieStore() {
			let securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
			return securityPolicy.medavieMoneris;
		},
		locale() {
			return this.$store.state.i18n.locale;
		}
	},
	watch: {
		locale() {
			this.getMonerisTicket();
			document.getElementById('moneris').contentWindow.location.reload();
		}
	},
	async mounted() {
		window.onmessage = function (e) {
			if (e?.data && e?.isTrusted && e?.origin === window.location.origin) {
				switch (e.data) {
					case 'error':
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
						break;
					case 'cancel':
						this.navigateBack();
						break;
					case 'receipt':
						this.getMonerisReciept();
						break;
				}
			}
		}.bind(this);
		await this.getMonerisTicket();

		// Set this value to be used by the app.vue file to determine if the Header/footer should
		// display with the loading animation.
		await sessionStorage.setItem('isIframe', true);
	},
	async beforeDestroy() {
		sessionStorage.removeItem('isIframe');
	},
	methods: {
		iframeLoadComplete() {
			setTimeout(() => {
				// add a small delay to smooth out user experience
				// without this the iframe 'pops' into view
				this.$store.dispatch('updateLoading', false);
			}, 500);
		},
		retrieveMonerisHtmlString(monerisTicket) {
			let updatedMonerisHtml = MonerisHtml.MONERIS_HTML_FOR_IFRAME_DEV;
			updatedMonerisHtml = updatedMonerisHtml.replace(
				'MONERIS_URL',
				window.ENV.VUE_APP_MONERIS_GATEWAY_URL
			);
			updatedMonerisHtml = updatedMonerisHtml.replace(
				'MONERIS_ENVIRONMENT',
				window.ENV.VUE_APP_MONERIS_ENVIRONMENT
			);
			updatedMonerisHtml = updatedMonerisHtml.replace('MONERIS_TICKET', monerisTicket);

			this.monerisHtmlText = updatedMonerisHtml;
		},
		/**
		 * Get the ticket to use with the moneris store. This will create the checkout screen for the member.
		 */
		async getMonerisTicket() {
			this.$store.dispatch('updateLoading', true);
			CreditCard.getMonerisTicket(
				sessionStorage.getItem('email'),
				this.isMedavieStore,
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			)
				.then(async (response) => {
					if (response && (response.status === 201 || response.status === 200)) {
						this.ticket = response.data.ticket;
						await this.retrieveMonerisHtmlString(this.ticket);
					} else {
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
					}
				})
				.finally(() => {
					this.$store.dispatch('updateLoading', false);
				});
		},
		/**
		 * Once the member has update their info use the ticket to get a reciept from the moneris side so we can update or add the members credit info.
		 */
		async getMonerisReciept() {
			this.$store.dispatch('updateLoading', true);
			CreditCard.getMonerisReciept(
				sessionStorage.getItem('email'),
				this.ticket,
				this.isMedavieStore,
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			)
				.then(async (response) => {
					if (response && (response.status === 201 || response.status === 200)) {
						this.updateOrCreateCreditCardInfo(response.data);
					} else {
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
					}
				})
				.finally(() => {
					this.$store.dispatch('updateLoading', false);
				});
		},
		/**
		 * Check if the member has existing credit card info
		 */
		updateOrCreateCreditCardInfo(monerisRecieptInfo) {
			CreditCard.getMaskedCreditCard(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			).then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					//If credit card does exist then we update in rainforest
					this.updateCreditCardInfo(monerisRecieptInfo, response.data.cardId);
				} else if (response && response.status === 204) {
					//If credit card does not exists then we create in rainforest
					this.addCreditCardInfo(monerisRecieptInfo);
				} else {
					this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
				}
			});
		},
		updateCreditCardInfo(monerisRecieptInfo, cardId) {
			this.$store.dispatch('updateLoading', true);
			CreditCard.updateCreditCard(
				sessionStorage.getItem('email'),
				monerisRecieptInfo.issuerId,
				monerisRecieptInfo.token,
				cardId,
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			)
				.then((response) => {
					if (response && (response.status === 201 || response.status === 200)) {
						this.$store.dispatch('updateLoading', false);
						this.$router.push({
							name: Routes.PREMIUM_PAYMENT_METHOD,
							params: {
								success: true,
								maskedPan: monerisRecieptInfo.f4l4,
								expiryDate: monerisRecieptInfo.expiryDate
							}
						});
					} else {
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
					}
				})
				.finally(() => {
					this.$store.dispatch('updateLoading', false);
				});
		},
		addCreditCardInfo(monerisRecieptInfo) {
			this.$store.dispatch('updateLoading', true);
			CreditCard.addCreditCard(
				sessionStorage.getItem('email'),
				monerisRecieptInfo.issuerId,
				monerisRecieptInfo.token,
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			)
				.then((response) => {
					if (response && (response.status === 201 || response.status === 200)) {
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, query: { success: true } });
						this.$router.push({
							name: Routes.PREMIUM_PAYMENT_METHOD,
							params: {
								success: true,
								maskedPan: monerisRecieptInfo.f4l4,
								expiryDate: monerisRecieptInfo.expiryDate
							}
						});
					} else {
						this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD, params: { error: true } });
					}
				})
				.finally(() => {
					this.$store.dispatch('updateLoading', false);
				});
		},
		navigateBack() {
			this.$router.push({ name: Routes.PREMIUM_PAYMENT_METHOD });
		},
		isEnglish() {
			return this.$store.state.i18n.locale === 'en' ? true : false;
		},
		logo() {
			return this.isEnglish()
				? require('@/assets/Secured-by-Moneris.svg')
				: require('@/assets/Securise-par-Moneris.svg');
		}
	}
};
</script>

<style lang="scss" scoped>
.moneris-card {
	width: 100%;
	position: relative;
	overflow: auto;
}

::v-deep .card-body {
	overflow: hidden;
	min-width: 650px;
	padding: 0;
}
.moneris-mobile-card {
	& ::v-deep .card-body {
		min-width: 100%;
	}
}
.iframe {
	width: 100%;
	border: none;
	height: 55em;
	display: block;
}
.moneris-subtitle {
	font-size: 21px;
	font-weight: 300;
	font-family: $lato-font-family;
}
::v-deep .page-title {
	margin-bottom: 0px;
}
.moneris-logo {
	max-width: 189px;
}
</style>

<i18n>
{
	"en": {
		"title": "Update Your Premium Payment Method",
		"subTitle": "Enter your credit card number below and submit.",
		"imageAlt": "Secured by Moneris",
		"error": {
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
	},
	"fr": {
		"title": "Modifiez votre mode de paiement des primes",
		"subTitle": "Entrez votre numéro de carte de crédit ci-dessous et soumettez votre changement.",
		"imageAlt": "Securise par Moneris",
		"error": {
			"wentWrongHeader": "Une erreur sest produite",
			"wentWrong": "Nous sommes désolés, une erreur sest produite. Veuillez réessayer plus tard."
		}
	}
}
</i18n>
