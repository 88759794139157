<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<span>
		<p class="pagination-info">
			<span v-if="$slots['additional-text']"><slot name="additional-text"></slot><br /></span>
			{{ `Page ${currentPage} ${$t('of')} ${totalPages}` }}
		</p>
		<BPagination
			:value="currentPage"
			class="pagination-nav"
			hide-goto-end-buttons
			:total-rows="totalRows"
			:per-page="perPage"
			:automation-id="getAutomationId('serchResultsCard')"
			:data-tracking-id="getAutomationId('serchResultsCard')"
			align="center"
			limit="3"
			@input="pageChange"
		>
			<template #prev-text>
				<FontAwesomeIcon aria-hidden="true" :icon="['fal', 'angle-left']" />
			</template>
			<template #next-text>
				<FontAwesomeIcon aria-hidden="true" :icon="['fal', 'angle-right']" /></template
		></BPagination>
	</span>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BPagination } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';

/**
 * Component renders a form.
 */
// @vue/component
@Component({
	name: 'BasePagination',
	components: {
		BPagination
	},
	mixins: [IdMixin],
	props: {
		currentPage: {
			type: Number,
			default: 1
		},
		totalRows: {
			type: Number,
			default: 2
		},
		perPage: {
			type: Number,
			default: 50
		}
	}
})
export default class BasePagination extends Vue {
	totalPages = this.totalRows >= 50 ? Math.ceil(this.totalRows / this.perPage) : 1;

	pageChange(page) {
		this.$emit('input', page);
	}
}
</script>

<style lang="scss" scoped>
.pagination-nav {
	margin-bottom: 0px;
}

.pagination-nav ::v-deep .page-link {
	height: 40px;
	width: 36px;
	font-size: 18px;
	color: $blue;
}

.pagination-nav ::v-deep span svg {
	font-size: 1.4em;
}

.pagination-nav ::v-deep span svg path {
	color: $blue;
}

.pagination-nav ::v-deep .disabled span svg path {
	color: $icon-grey;
}

.pagination-nav ::v-deep .page-item.active .page-link {
	background-color: $blue;
	color: $white;
	border-color: $blue;
}
.pagination-info {
	font-weight: $font-regular;
	font-size: $body-regular-button-font-size;
	font-family: $josefin-font-family;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
</style>
<i18n>
{
  "en": {
	"of": "of"
  },
  "fr": {
	"of": "sur"
  }
}
</i18n>
