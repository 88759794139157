export var MONERIS_HTML_FOR_IFRAME_DEV = `
<html>
    <head>
        <meta name="viewport" content='width=device-width, initialscale=1.0, maximum-scale=1.0, user-scalable=0'>        
        <script src="MONERIS_URL"></script>
        <style>
            .moneris-checkout {
                height: 55em !important;
            }
        </style>
    </head>
    <body onload="
        let ticket = 'MONERIS_TICKET';
        
        function myPageLoad(callbackMessage) {
            try {
                let callbackObj = JSON.parse(callbackMessage);
                let responseCode = callbackObj.response_code;

                if (responseCode.localeCompare('001') === 0) {
                    window.top.postMessage('loaded', '*');
                } else {
                    window.top.postMessage('error', '*');
                }
            } catch(error) {
                window.top.postMessage('error', '*');
            }
        }

        function myCancelTransaction() {
            myCheckout.closeCheckout(ticket);
            try {
                window.top.postMessage('cancel', '*');
            } catch(error) {
                window.top.postMessage('error', '*');
            }
        }

        function myErrorEvent() {
            myCheckout.closeCheckout(ticket);
            try {
                window.top.postMessage('error', '*');
            } catch(error) {
                window.top.postMessage('error', '*');
            }
        }

        function myPaymentReceipt(callbackMessage) {
            try {
                let callbackObj = JSON.parse(callbackMessage);
                let responseCode = callbackObj.response_code;

                if (responseCode.localeCompare('001') === 0) {
                    window.top.postMessage('receipt', '*');
                } else {
                    myCheckout.closeCheckout(ticket);
                    window.top.postMessage('error', '*');
                }
            } catch(error) {
                myCheckout.closeCheckout(ticket);
                window.top.postMessage('error', '*');
            }
        }

        var myCheckout = new monerisCheckout();
        myCheckout.setMode('MONERIS_ENVIRONMENT');
        myCheckout.setCheckoutDiv('monerisCheckout');
        myCheckout.setCallback('page_loaded', myPageLoad);
        myCheckout.setCallback('cancel_transaction', myCancelTransaction);
        myCheckout.setCallback('error_event', myErrorEvent);
        myCheckout.setCallback('payment_receipt', myPaymentReceipt);

        
        // Ticket Number from POST request
        myCheckout.startCheckout(ticket);
    ">
        <div id="monerisCheckout" class="moneris-checkout"></div>
    </body>
</html> 
`;
