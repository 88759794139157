<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseCard :automation-id="getAutomationId('change-password-form')" class="base-card">
		<BRow>
			<BCol>
				<h2>{{ $t('title') }}</h2>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<p>{{ $t('subtitle') }}</p>
			</BCol>
		</BRow>
		<BaseForm
			ref="premium-payments-search-date"
			:automation-id="getAutomationId('premiumPaymentsSearchDate')"
			@keydown.enter="submit"
			@submit="onClick"
		>
			<BRow>
				<BCol cols="12" md="6" xl="5">
					<BaseDatePicker
						ref="fromDate"
						datepicker-id="fromDate"
						:value="dateRange.fromDate"
						:label="$t('label.premiumPaidFromDate')"
						:placeholder="$t('datepicker.fromDate')"
						:initial-date="dateRange.fromDate"
						:min-selectable-date="getMinSelectableDate()"
						:max-selectable-date="getMaxDate()"
						:min-error-date="getMinErrorDate()"
						:max-error-date="getMaxDate()"
						start-date
						:required="true"
						:required-error-msg="$t('error.fromdateRequired')"
						:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
						:before-max-date-error-msg="$t('error.maxDate')"
						pill
						is-left
						:automation-id="getAutomationId('start')"
						data-tracking-id="premium-payment-statements-form-from-date-picker"
						@input="updateFromDate"
					>
						<template #suffix>
							<span class="suffix-text ml-3">{{ $t('label.dateSuffix') }}</span>
						</template>
					</BaseDatePicker>
				</BCol>
				<BCol cols="12" md="6" xl="5">
					<BaseDatePicker
						ref="toDate"
						datepicker-id="toDate"
						:value="dateRange.toDate"
						:label="$t('label.premiumPaidToDate')"
						:placeholder="$t('datepicker.toDate')"
						:min-selectable-date="getMinSelectableDate()"
						:max-selectable-date="getMaxDate()"
						:min-error-date="getMinErrorDate()"
						:max-error-date="getMaxDate()"
						:start-date="false"
						:required="true"
						:from-date="dateRange.fromDate"
						:required-error-msg="$t('error.todateRequired')"
						:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
						:before-max-date-error-msg="$t('error.maxDate')"
						pill
						is-left
						:automation-id="getAutomationId('end')"
						@input="updateToDate"
					>
						<template #suffix>
							<span class="suffix-text ml-3">{{ $t('label.dateSuffix') }}</span>
						</template>
					</BaseDatePicker></BCol
				>
			</BRow>
			<BRow>
				<BCol>
					<BaseButton
						class="search-button m-0"
						variant="primary"
						:label="$t('button.search')"
						pill
						:automation-id="getAutomationId('searchByDate')"
						type="submit"
					></BaseButton>
				</BCol>
			</BRow>
		</BaseForm>
	</BaseCard>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseForm from '@/components/common/base/BaseForm';
//utils
import { format } from 'date-fns';

/**
 * This component is responsible to render a member table.
 */
// @vue/component
@Component({
	name: 'PremiumPaymentsSearchByDateCard',
	components: {
		BRow,
		BCol,
		BaseDatePicker,
		BaseButton,
		BaseCard,
		BaseForm
	},
	mixins: [IdMixin]
})
export default class PremiumPaymentsSearchByDateCard extends Vue {
	dateRange = {
		fromDate: format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'),
		toDate: this.getMaxDate()
	};

	/**
	 * get the minimum date a member could choose. This is 3 years before todays date.
	 */
	getMinSelectableDate() {
		return format(new Date(new Date().getFullYear() - 3, 0, 1), 'yyyy-MM-dd');
	}

	/**
	 * get the minimum date a member could choose. This is 3 years before todays date.
	 */
	getMinErrorDate() {
		var minDate = new Date();
		minDate.setFullYear(minDate.getFullYear() - 3);
		minDate.setMonth(0);
		minDate.setDate(1);
		return format(minDate, 'yyyy-MM-dd');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	/**
	 * emit click event
	 */
	async onClick() {
		this.$emit('click', this.dateRange);
	}

	/**
	 * Update from date
	 * @param {string} fromDate
	 */
	updateFromDate(fromDate) {
		if (fromDate) {
			this.dateRange.fromDate = format(
				new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]),
				'yyyy-MM-dd'
			);
		}
	}

	/**
	 * Update to date
	 * @param {string} to date
	 */
	updateToDate(toDate) {
		if (toDate) {
			this.dateRange.toDate = format(
				new Date(toDate.split('-')[0], toDate.split('-')[1] - 1, toDate.split('-')[2]),
				'yyyy-MM-dd'
			);
		}
	}
}
</script>

<style lang="scss" scoped>
.search-by-date {
	border-radius: 10px;
	border: 1px solid $light-grey;
	margin-left: 0em;
}

h2 {
	font-size: $body-large-bold-font-size;
	font-weight: $font-regular;
	margin-bottom: 0;
}

.suffix-text {
	font-size: $body-small-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
}

.search-button {
	min-width: 100px;
	border: 1px solid #0079ad;
	border-radius: 30px;
	font-family: 'Josefin Sans', sans-serif;
	font-size: $body-regular-button-font-size;
	font-weight: normal;
	margin-left: 1em;
}
</style>

<i18n>
{
    "en": {
		"title": "Search your statements",
		"subtitle": "Search, review and download up to 3 years of your premium history.",
		"button": {
			"search": "Search"
		},
		"label": {
			"dateSuffix": "(DD/MM/YYYY)",
			"premiumPaidFromDate": "Premiums Paid Start Date",
			"premiumPaidToDate": "Premiums Paid End Date"
		},
		"datepicker": {
			"fromDate": "From Date",
			"toDate": "To Date"
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "Our search results are limited to three (3) years of history.",
			"fromdateRequired": "Please enter a start date.",
			"todateRequired": "Please enter an end date.",
			"maxDate": "The date you’ve entered is in the future. Please check the date and try again."
		}
    },
    "fr": {
		"title": "Cherchez vos relevés",
		"subtitle": "Cherchez, consultez et téléchargez votre historique de primes des trois dernières années.",
		"button": {
			"search": "Recherche"
		},
		"label": {
			"dateSuffix": "(JJ/MM/AAAA)",
			"premiumPaidFromDate": "Date de début des primes payées",
			"premiumPaidToDate": "Date de fin des primes payées"
		},
		"datepicker": {
			"fromDate": "Du ",
			"toDate": "Au "
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "Il n’y a que 3 ans d’historique dans les résultats de recherche.  ",
			"fromdateRequired": "Veuillez entrer une date de début.",
			"todateRequired": "Veuillez entrer une date de fin.",
			"maxDate": "La date que vous avez entrée est ultérieure à la date d’aujourd’hui. Veuillez corriger la date et essayer à nouveau."
		}
    }
}
</i18n>
