<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<BRow v-if="!showSearchResults">
			<BCol>
				<PremiumPaymentsSearchByDateCard
					id="PremiumPaymentsSearchByDateCard"
					automation-id="premiumPaymentStatementsPage'"
					:date-range="dateRange"
					@click="getPremiumsByDateRange"
				/>
			</BCol>
		</BRow>
		<BRow v-if="!showSearchResults">
			<BCol>
				<PremiumPaymentsSearchByYearCard
					id="PremiumPaymentsSearchByYearCard"
					automation-id="premiumPaymentStatementsPage'"
					@click="getPremiumsPDFByYear"
				/>
			</BCol>
		</BRow>
		<BRow v-if="showSearchResults">
			<BCol>
				<PremiumPaymentSearchResultsCard
					id="premiumPaymentSearchResultsCard"
					:date-range="dateRange"
					:payment-history="premiumPaymentHistory"
					:columns="getColumns()"
					automation-id="premiumPaymentSearchResultsCard"
					@click="getPremiumsPDFByDateRange"
					@hidePage="hideSearchPage"
				>
				</PremiumPaymentSearchResultsCard>
			</BCol>
		</BRow>
		<BRow v-if="showSearchResults" class="mb-4">
			<BCol>
				<BaseButton
					class="m-0"
					variant="primary"
					:label="$t('button.download')"
					pill
					is-right
					:icon="['fal', 'print']"
					icon-position="left"
					automation-id="searchResultsPdfDownload"
					:data-tracking-id="'search-results-pdf-download'"
					@click="getPremiumsPDFByDateRange"
				></BaseButton>
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import Vue from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import * as PremiumStatements from '@/constants/PremiumStatements.js';
import PremiumStatementsModel from '@/models/PremiumStatements.js';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT } from '@/constants/Routes.js';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import IdMixin from '@/mixins/id';
import { formatDateForUi } from '@/utils/date';
import BaseButton from '@/components/common/base/BaseButton.vue';
import PremiumPaymentSearchResultsCard from '@/components/manage-your-account/premium-statements/PremiumPaymentSearchResultsCard';
import PremiumPaymentsSearchByDateCard from '@/components/manage-your-account/premium-statements/PremiumPaymentsSearchByDateCard';
import PremiumPaymentsSearchByYearCard from '@/components/manage-your-account/premium-statements/PremiumPaymentsSearchByYearCard';

// @vue/component
@Component({
	name: 'PremiumStatementsPage',
	components: {
		BRow,
		BCol,
		BaseButton,
		PremiumPaymentsSearchByDateCard,
		PremiumPaymentsSearchByYearCard,
		PremiumPaymentSearchResultsCard,
		ManageYourAccountTemplate
	},
	mixins: [IdMixin, BreadcrumbsManager],
	props: {}
})
export default class PremiumStatementsPage extends Vue {
	error = {
		title: '',
		body: ''
	};
	premiumPaymentHistory = null;
	showSearchResults = false;
	dateRange = {
		fromDate: null,
		toDate: null
	};

	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personal', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.memberInfo', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personal', 'fr') }
			]
		);
		window.addEventListener(
			'unload',
			function () {
				if (localStorage.getItem(PremiumStatements.KEY_LOCAL_STORAGE_DATE_RANGE)) {
					localStorage.removeItem(PremiumStatements.KEY_LOCAL_STORAGE_DATE_RANGE);
				}
			},
			false
		);
	}

	async beforeDestroy() {
		if (localStorage.getItem(PremiumStatements.KEY_LOCAL_STORAGE_DATE_RANGE)) {
			localStorage.removeItem(PremiumStatements.KEY_LOCAL_STORAGE_DATE_RANGE);
		}
	}

	/**
	 * Handle on click event.
	 */
	async getPremiumsPDFByYear(year) {
		this.$store.dispatch('updateLoading', true);
		let locale = this.$store.state.i18n.locale;
		let response = await PremiumStatementsModel.getPremiumsPDFByYear(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			locale,
			year
		);

		if (response && response.status < 204) {
			let filename = locale === 'fr' ? 'Détails-des-primes' : 'PremiumDetail';
			this.downloadPremiumsPdf(response.data, filename);
		} else if (response && response.status === 204) {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.title'),
				err: this.$t('error.premiumPaymentsNotAvailableByYear')
			});
		} else {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.wentWrongHeader'),
				err: this.$t('error.wentWrong')
			});
		}

		this.showSearchResults = false;

		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * Handle on click event.
	 */
	async getPremiumsPDFByDateRange() {
		this.$store.dispatch('updateLoading', true);
		let fromDate = this.dateRange.fromDate.replaceAll('-', '');
		let toDate = this.dateRange.toDate.replaceAll('-', '');
		let locale = this.$store.state.i18n.locale;
		let response = await PremiumStatementsModel.getPremiumsPDFByDateRange(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			locale,
			fromDate,
			toDate
		);

		if (response && response.status < 204) {
			let filename = locale === 'fr' ? 'Détails-des-primes' : 'PremiumDetail';
			this.downloadPremiumsPdf(response.data, filename);
		} else if (response && response.status === 204) {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.title'),
				err: this.$t('error.premiumPaymentsNotAvailableByDate')
			});
		} else {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.wentWrongHeader'),
				err: this.$t('error.wentWrong')
			});
		}

		this.$store.dispatch('updateLoading', false);
	}

	async getPremiumsByDateRange(dateRange) {
		this.$store.dispatch('updateLoading', true);
		this.dateRange = dateRange;
		const locale = this.$store.state.i18n.locale;
		let response = await PremiumStatementsModel.getPremiumsByDateRange(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			locale,
			dateRange.fromDate.replaceAll('-', ''),
			dateRange.toDate.replaceAll('-', '')
		);
		if (response && response.status < 204 && response.data.premiums) {
			const formatDate = (paymentDate) => {
				return formatDateForUi(paymentDate, this.$store.state.i18n.locale);
			};

			let premiums = response.data.premiums?.map((premium) => ({
				...premium,
				transactionDate: formatDate(premium.transactionDate),
				amount:
					locale === 'fr'
						? premium.amount
								.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })
								.replace('CA', '')
						: premium.amount.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })
			}));
			this.premiumPaymentHistory = {
				totalAmount:
					locale === 'fr'
						? response.data.totalAmount
								.toLocaleString('fr-CA', {
									style: 'currency',
									currency: 'CAD'
								})
								.replace('CA', '')
						: response.data.totalAmount.toLocaleString('en-CA', {
								style: 'currency',
								currency: 'CAD'
						  }),
				premiums: premiums
			};
			if (premiums) {
				this.showSearchResults = true;
			}
		} else if (response.status === 204) {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.title'),
				err: this.$t('error.premiumPaymentsNotAvailableByDate')
			});
		} else {
			this.$store.dispatch('error', {
				name: 'premium-statement-error',
				title: this.$t('error.wentWrongHeader'),
				err: this.$t('error.wentWrong')
			});
		}
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	downloadPremiumsPdf(data, filename) {
		if (navigator.appVersion.toString().indexOf('.NET') > 0)
			window.navigator.msSaveBlob(new Blob([data]), `Identification.pdf`);
		else {
			const demoMode = sessionStorage.getItem('demoActive');
			let fileURL = require('@/assets/blank-demo-document.pdf');
			if (!demoMode) {
				fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			}

			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		}
	}

	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getColumns() {
		return [
			{
				key: 'transactionDate',
				label: this.$t('column.transactionDate'),
				thClass: 'th-date-class',
				tdClass: 'td-date-class'
			},
			{
				key: 'type',
				label: this.$t('column.type'),
				thClass: 'th-type-class',
				tdClass: 'td-type-class'
			},
			{
				key: 'amount',
				label: this.$t('column.amount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			}
		];
	}

	hideSearchPage() {
		this.showSearchResults = false;
	}

	closeModal() {
		this.isModalVisible = false;
	}
}
</script>
<style lang="scss" scoped>
.container-margin {
	margin: 0em 2em;
	@media (max-width: 768px) {
		width: 87%;
		margin: auto;
	}
}
.container-row-margin {
	margin-bottom: 2em;
}
.table {
	border: 1px solid $light-grey;
}
.thead {
	background: $light-grey;
}
.download-button {
	margin-bottom: 1em;
}

::v-deep .btn .svg-left {
	margin-right: 0.7em;
}
</style>
<i18n>
{
	"en": {
		"button": {
			"download": "Download"
		},
		"breadcrumb": {
			"home": "Home",
			"memberInfo": "Manage your account",
			"personal": "Review your premium statements"
		},
        "title": "Premium Payment Statements",
		"column": {
			"transactionDate": "Transaction Date",
			"type": "Transaction Type",
			"amount": "Transaction Amount"
		},
		"error": {
			"title": "Something went wrong",
			"premiumPaymentsNotAvailableByDate": "We can't find any results related to your search. Please try a new search.",
			"premiumPaymentsNotAvailableByYear": "We can't find any results for that year. Please try a new search.",
			"wentWrongHeader": "Something went wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
    },
    "fr": {
		"button": {
			"download": "Télécharger"
		},
		"breadcrumb": {
			"home": "Accueil",
			"memberInfo": "Gérer votre compte",
			"personal": "Consulter vos relevés de primes "
		},
        "title": "Relevés de paiement des primes",
		"column": {
			"transactionDate": "Date de la transaction",
			"type": "Type de transaction",
            "amount":"Montant de la transaction"
		},
		"error": {
			"title": "Une erreur s’est produite",
			"premiumPaymentsNotAvailableByDate": "Nous n’avons trouvé aucun résultat pour votre recherche. Veuillez essayer une nouvelle recherche.",
			"premiumPaymentsNotAvailableByYear": "Nous n’avons trouvé aucun résultat pour cette année. Veuillez essayer une nouvelle recherche.",
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}
    }
}
</i18n>
